/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import ConfigRevisionPage from '@/pages/ConfigRevision/ConfigRevisionPage';
import { HTTPError } from '@/v1/components/HTTPError';

const regex = /^\d+$/;

export function ConfigRevisionRoute() {
  const { nodeConfigId } = useParams<'nodeConfigId'>();
  const { configRevisionNumber } = useParams<'configRevisionNumber'>();

  if (!nodeConfigId || !uuidValidate(nodeConfigId)) {
    return <HTTPError httpStatus="404" />;
  }
  if (!configRevisionNumber || !regex.test(configRevisionNumber!)) {
    return <HTTPError httpStatus="404" />;
  }

  return <ConfigRevisionPage nodeConfigId={nodeConfigId} configRevisionNumber={Number(configRevisionNumber)} />;
}
