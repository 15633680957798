/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { EnvironmentBadge } from '@/components/EnvironmentBadge';
import { Flex } from '@/primitives/Flex';
import { Theme } from '@/primitives/Theme';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { isDarkModeDefault, mimicEnv } from '@/utils/environments';
import { JobBanners } from '@/v1/components/JobBanners/JobBanners';
import { LeftMenu } from '@/v1/components/menus/LeftMenu';
import { MimicErrorBoundary } from '@/v1/components/MimicErrorBoundary';
import { MimicLogo } from '@/v1/components/MimicLogo';
import { SupportFooter } from '@/v1/components/SupportFooter';
import { TenantSwitcher } from '@/v1/components/TenantSwitcher/TenantSwitcher';
import { AuthenticatedRoute } from '@/v1/routes/AuthenticatedRoute';
import { useTenantChannel } from '@/v1/utils/hooks/useTenantChannel';
import { useWebsocket } from '@/v1/utils/hooks/useWebsocket';

import { Tenant } from './client';
import { Container } from './primitives/Container';
import { Content } from './primitives/Content';
import { Header } from './primitives/Header';
import { Layout } from './primitives/Layout';
import { Sider } from './primitives/Sider';

export function MainLayout() {
  const navigate = useNavigate();
  const { tenantID } = useParams();
  const { pathname } = useLocation();
  const currentUser = useMgmtPlaneStateStore((state) => state.userProfile);
  const setSelectedTenantID = useMgmtPlaneStateStore((state) => state.setSelectedTenantID);
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID) || tenantID;
  useWebsocket();
  useTenantChannel(selectedTenantID);
  const environment = mimicEnv();
  const dark = isDarkModeDefault();

  useEffect(() => {
    if (!tenantID) return;
    if (!currentUser) return;
    if (currentUser.role !== 'superadmin') return;
    setSelectedTenantID(tenantID);
  }, [currentUser, tenantID, selectedTenantID, setSelectedTenantID]);

  const onSelectTenant = (tenant: Tenant) => {
    if (currentUser?.role !== 'superadmin') return;
    setSelectedTenantID(tenant.id);

    // if current route is /tenant/:tenantID, where tenantID is a uuid, replace the route with the new tenantID
    if (pathname.includes('/tenants/')) {
      const newPath = pathname.replace(/\/tenants\/[a-f0-9-]+/, `/tenants/${tenant.id}`);
      navigate(newPath);
    }
  };

  return (
    <MimicErrorBoundary>
      <AuthenticatedRoute>
        <Layout>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <JobBanners tenantId={tenantID} />
            <Layout hasSider>
              <Theme dark={dark}>
                <Sider>
                  <Container gap="none" justify="space-between" full>
                    <Container.Fixed>
                      <Flex align="center">
                        <Header>
                          <MimicLogo width="6rem" height="2rem" />
                        </Header>
                        <EnvironmentBadge environment={environment} />
                      </Flex>

                      <TenantSwitcher
                        currentUser={currentUser}
                        selectedTenantID={selectedTenantID}
                        onSelectTenant={onSelectTenant}
                      />
                    </Container.Fixed>
                    <Container.Expand>
                      <LeftMenu
                        tenantID={selectedTenantID!}
                        currentUser={currentUser!}
                        navigate={navigate}
                        currentPath={pathname}
                      />
                    </Container.Expand>
                  </Container>
                </Sider>
              </Theme>
              <Content>
                <Outlet />
                <SupportFooter />
              </Content>
            </Layout>
          </div>
        </Layout>
      </AuthenticatedRoute>
    </MimicErrorBoundary>
  );
}
