/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { ContentContainer } from '@/components/ContentContainer';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useConfigRevisionByIdQuery } from '@/hooks/query/useConfigRevisionByIdQuery';
import { useNodeConfigByIdQuery } from '@/hooks/query/useNodeConfigByIdQuery';
import { NodeConfigurationRevisionSourceCode } from '@/pages/ConfigRevision/NodeConfigurationRevisionSourceCode';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Skeleton } from '@/primitives/Skeleton';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { BackButton } from '@/v1/components/Buttons/BackButton';

export type ConfigRevisionPageProps = {
  nodeConfigId: string;
  configRevisionNumber: number;
};

export default function ConfigRevisionPage({ nodeConfigId, configRevisionNumber }: ConfigRevisionPageProps) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const nodeConfigQuery = useNodeConfigByIdQuery({ nodeConfigId });
  const configRevisionQuery = useConfigRevisionByIdQuery({
    nodeConfigId,
    configRevisionNumber,
  });

  const getNodeConfigurationPath = () => `/tenants/${tenantId}/node-configs/${nodeConfigId}`;

  if (nodeConfigQuery.isPending || !nodeConfigQuery.data) return <Skeleton loading />;
  if (configRevisionQuery.isPending || !configRevisionQuery.data) return <Skeleton loading />;

  return (
    <ContentContainer width="100%">
      <Flex vertical gap="md">
        <Container>
          <BackButton to={`/tenants/${tenantId}/node-configs/${nodeConfigId}`} />
        </Container>
        <QueryWrapper queryResult={nodeConfigQuery}>
          {(nodeConfigData) => (
            <QueryWrapper queryResult={configRevisionQuery}>
              {(configRevisionData) => (
                <NodeConfigurationRevisionSourceCode
                  nodeConfiguration={nodeConfigData.data}
                  nodeConfigRevision={configRevisionData.data}
                  getNodeConfigurationPath={getNodeConfigurationPath}
                />
              )}
            </QueryWrapper>
          )}
        </QueryWrapper>
      </Flex>
    </ContentContainer>
  );
}
