/*
 * Copyright Mimic Networks, Inc. 2024.
 */

// This function will return Prod, UAT, Dev, QA, Arena, Local
export function mimicEnv() {
  const stringUrl = String(window.mimicConfig.MIMIC_MGMT_PLANE_API);
  if (stringUrl === 'undefined') {
    return 'local';
  }

  const url = new URL(stringUrl);
  if (url === null) {
    return 'local';
  }

  if (url.host === null) return 'local';
  const hostParts = url.host.split('.');

  if (hostParts[0] === 'localhost') {
    return 'local';
  }

  const env = hostParts[1];

  switch (env) {
    case 'prod':
      return 'prod';

    case 'uat':
      return 'uat';

    case 'dev':
      return 'dev';

    case 'qa':
      return 'qa';

    case 'arena':
      return 'arena';

    default:
      return 'local';
  }
}

export function isDarkModeDefault() {
  return mimicEnv() !== 'prod';
}
