/*
 * Copyright Mimic Networks, Inc. 2025.
 */

import { Form, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ClearHallmarks } from '@/client';
import { StatusText } from '@/components/StatusText';
import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { ClearHallmarksForm } from '@/pages/Hallmarks/ClearHallmarksForm';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { useClearHallmarksMutation } from '@/v1/utils/hooks/useClearHallmarksMutation';

export function ClearHallmarksModal() {
  const { t } = useMimicTranslation('node');
  const navigate = useNavigate();
  const params = useParams<'id'>();
  const nodeId = params.id;
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState<'unconfirmed' | 'pending' | 'confirmed'>('unconfirmed');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [form] = Form.useForm<ClearHallmarks>();
  const [message] = useMessage();

  const closeModal = () => {
    setOpen(false);
  };

  const afterClose = () => {
    navigate(-1);
  };

  const afterSuccess = () => {
    setStatus('confirmed');
    form.resetFields();

    setTimeout(() => {
      setStatus('unconfirmed');
    }, 2000);
  };

  const { mutate } = useClearHallmarksMutation(tenantID!, nodeId!, {
    onSuccess: () => {
      afterSuccess();
    },
    onError: () => {
      message.error(t('clearHallmarksModal.error'));
    },
  });

  async function validateAndSubmit() {
    try {
      setStatus('pending');
      const formParams = await form.validateFields();
      mutate(formParams);
    } catch (error) {
      setStatus('unconfirmed');
    }
  }

  return (
    <Modal
      title={t('clearHallmarksModal.title')}
      centered
      afterClose={afterClose}
      open={open}
      onOk={() => validateAndSubmit()}
      onCancel={closeModal}
      confirmLoading={status === 'pending'}
      okButtonProps={{ disabled: status === 'pending' }}
      cancelButtonProps={{ disabled: status === 'pending' }}
      okText={
        <StatusText
          status={status}
          text={{
            unconfirmed: t('clearHallmarksModal.buttonTextUnconfirmed'),
            pending: t('clearHallmarksModal.buttonTextPending'),
            confirmed: t('clearHallmarksModal.buttonTextConfirmed'),
          }}
        />
      }
    >
      <ClearHallmarksForm form={form} />
    </Modal>
  );
}
