/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, UserResponse, CreateUser } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export type NewUserMutationConfig = {
  onSuccess?: (data: UserResponse, variables: CreateUser) => void;
  onError?: (error: unknown, variables: CreateUser) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

/**
 * Hook for creating a new user through the management plane API.
 *
 * @param config.onSuccess - Called after successful user creation
 * @param config.onError - Called if creation fails
 * @param config.onSettled - Called after mutation completes (success or failure)
 *
 * @returns React Query mutation result for creating user
 *
 * @example
 * ```tsx
  const { mutate } = useNewUserUserMutation({
 *   onSuccess: (data) => console.log('User created:', data),
 *   onError: (error) => console.error('User creation failed:', error)
    },
  });
   * // Create user
 * mutate({ firstName: 'John', lastName: 'Doe', email: 'johndoe@mimic.com', role: 'admin' });
 * ```
 */

export function useNewUserMutation(config: NewUserMutationConfig) {
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const queryClient = useQueryClient();

  const { mutate, isError, error } = useMutation<UserResponse, ApiError, CreateUser>({
    mutationFn: (newUser: CreateUser) => {
      return mgmtPlaneAPIClient.users.createUser({
        tenantId: tenantId!,
        requestBody: {
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          email: newUser.email,
          role: newUser.role,
        },
      });
    },
    onSuccess: (data, variables) => {
      if (config?.onSuccess) {
        config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  });

  return { mutate, isError, error };
}
