/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';

import { PatchUser } from '@/client';
import { QueryWrapper } from '@/components/QueryWrapper';
import { useUserByIdQuery } from '@/hooks/query/useUserByIdQuery';
import { UpdateMemberForm } from '@/pages/Members/UpdateMemberForm';
import { UpdateMemberModal } from '@/pages/UpdateMember/UpdateMemberModal';

export type UpdateMemberPageProps = {
  userId: string;
};

export function UpdateMemberPage({ userId }: UpdateMemberPageProps) {
  const [form] = Form.useForm<PatchUser>();
  const userQuery = useUserByIdQuery({ userId });

  return (
    <UpdateMemberModal form={form}>
      <QueryWrapper queryResult={userQuery}>
        {(userData) => <UpdateMemberForm form={form} userData={userData} isLoading={userQuery.isLoading} />}
      </QueryWrapper>
    </UpdateMemberModal>
  );
}
