/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { UpdateMemberPage } from '@/pages/UpdateMember/UpdateMemberPage';
import { HTTPError } from '@/v1/components/HTTPError';

export function UpdateMemberRoute() {
  const { userID } = useParams<'userID'>();

  if (!userID || !uuidValidate(userID)) {
    return <HTTPError httpStatus="404" />;
  }

  return <UpdateMemberPage userId={userID} />;
}
