/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConfigRevision, NodeConfig } from '@/client/';
import { RelativeTime } from '@/components/RelativeTime';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Radio } from '@/primitives/Radio';
import { Text } from '@/primitives/Text';
import { ConfigRevisionInfo } from '@/v1/components/ConfigureNodesJobWizard/ConfigureNodesJobWizardSelectConfigRevision';
import { useConfigRevisionsQuery } from '@/v1/utils/hooks/useConfigRevisionsQuery';

export type ExpandedRowProps = {
  tenantID: string;
  nodeConfig: NodeConfig;
  selectedConfigRevisionInfo: ConfigRevisionInfo;
  setSelectedConfigRevisionInfo: (configRevision: ConfigRevisionInfo) => void;
  setShowConfigCode: (showConfigCode: boolean) => void;
  setError: (error: boolean) => void;
};

export function ExpandedConfigRowRender({
  tenantID,
  nodeConfig,
  selectedConfigRevisionInfo,
  setSelectedConfigRevisionInfo,
  setShowConfigCode,
  setError,
}: ExpandedRowProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');

  const configRevisionsQuery = useConfigRevisionsQuery({
    tenantId: tenantID,
    configId: nodeConfig.id,
    filters: {},
    sort: [],
    settings: { number: 1, size: 10 },
  });

  const revisions = configRevisionsQuery.data?.data || [];

  const onSelectConfig = (configRevision: ConfigRevision) => {
    setError(false);

    setSelectedConfigRevisionInfo({
      configId: nodeConfig.id,
      revisionId: configRevision.id,
      revisionNumber: configRevision.revisionNumber,
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {revisions.map((revision) => (
        <Flex key={revision.id} full align="center">
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '44px' }}>
            <div style={{ marginLeft: '44px' }}>
              <Radio
                dataTestId="select-config-revision"
                onClick={() => setShowConfigCode(true)}
                onChange={() => onSelectConfig(revision)}
                checked={selectedConfigRevisionInfo?.revisionId === revision.id}
              />
            </div>
            <Text type="subtle" size="sm" style={{ minWidth: '44px', maxWidth: '72px' }}>
              {`${t('rev')} #${revision.revisionNumber}`}
            </Text>
            <Text type="default" size="sm">
              {revision.description}
            </Text>
          </div>
          <div style={{ width: '164px' }}>
            <Text type="default" size="sm">
              <RelativeTime date={revision.createdAt} />
            </Text>
          </div>
        </Flex>
      ))}
    </div>
  );
}
