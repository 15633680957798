/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { LoadingOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { Job } from '@/client';
import { PageHeader } from '@/components/PageHeader';
import { RelativeTime } from '@/components/RelativeTime';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { AvatarAndName } from '@/v1/components/AvatarAndName';

import { JobDetailsVerticalDivider } from './JobDetailsVerticalDivider';

export type JobDetailsHeaderProps = {
  job: Job;
};

export function JobDetailsHeader({ job }: JobDetailsHeaderProps) {
  const { t } = useMimicTranslation('jobs');
  return (
    <PageHeader
      icon={<UnorderedListOutlined style={{ width: '18px', height: '18px' }} />}
      title={
        <Text type="subtle" size="md" strong style={{ lineHeight: '32px' }}>
          {t('jobDetailsTitle')}
        </Text>
      }
      text={
        <Flex vertical gap="sm">
          <Flex gap="xs">
            <Title data-testid="job-details-title" level={2} style={{ fontSize: '23px' }}>
              {job.name}
            </Title>
            {job.status === 'running' && (
              <LoadingOutlined style={{ fontSize: '20px' }} data-testid="job-running-icon" />
            )}
          </Flex>
          <Flex gap="sm">
            <AvatarAndName userInfo={job.createdBy} />
            <Flex gap="xs">
              <Text size="md" type="subtle" strong>
                {t('start')}
              </Text>
              <Text size="md" type="subtle" data-testid="job-date-created">
                <RelativeTime date={job.dateCreated} /> UTC
              </Text>
            </Flex>
            <Flex gap="xs">
              <Text size="md" type="subtle" strong>
                {t('end')}
              </Text>
              <Text size="md" type="subtle" data-testid="job-date-completed">
                {job.dateCompleted && (
                  <>
                    <RelativeTime date={job.dateCompleted} /> UTC
                  </>
                )}
                {!job.dateCompleted && '-'}
              </Text>
            </Flex>
            <Flex gap="xs">
              <JobDetailsVerticalDivider />
              <Text size="md" type="subtle" mono copyable>
                {job.id}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      }
      level={4}
      fontWeight="300"
    />
  );
}
