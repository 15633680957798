/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';
import { ColumnsType, ColumnType } from 'antd/es/table/interface';
import { useState } from 'react';

import { ConfigRevision, ConfigRevisionFilters, PaginatedConfigRevisionResponse } from '@/client';
import MimicHighlighter from '@/components/MimicHighlighter';
import { PageHeader } from '@/components/PageHeader';
import { RelativeTime } from '@/components/RelativeTime';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Avatar } from '@/primitives/Avatar';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Radio } from '@/primitives/Radio';
import { Text } from '@/primitives/Text';
import { UpdateParams } from '@/utils/params';
import { GuardedLink } from '@/v1/components/GuardedLink';
import { PaginatedTable } from '@/v1/components/PaginatedTable';

export type SelectableConfigRevision = ConfigRevision & { selected: boolean };

export type ConfigRevisionsListProps = {
  onFilterAndSortChange: UpdateParams<ConfigRevision, Partial<ConfigRevisionFilters>>;
  configRevisionsQueryResponse: UseQueryResult<PaginatedConfigRevisionResponse, Error>;
  getConfigRevisionPath: (configRevision: ConfigRevision) => string;
  onConfigRevisionSelect?: (configRevision: ConfigRevision) => void;
  defaultConfigRevision?: ConfigRevision;
};

export function ConfigRevisionsList({
  onFilterAndSortChange,
  configRevisionsQueryResponse,
  getConfigRevisionPath,
  onConfigRevisionSelect,
  defaultConfigRevision,
}: ConfigRevisionsListProps) {
  const { t } = useMimicTranslation('configRevisions');
  const [selectedConfigRevision, setSelectedConfigRevision] = useState<ConfigRevision | undefined>(
    defaultConfigRevision,
  );

  const onSelect = onConfigRevisionSelect
    ? (configRevision: SelectableConfigRevision) => {
        setSelectedConfigRevision(configRevision);
        onConfigRevisionSelect(configRevision);
      }
    : undefined;

  const columns = getConfigRevisionsColumns(
    t,
    configRevisionsQueryResponse.data?.meta?.filters || {},
    getConfigRevisionPath,
    onSelect,
  );

  const dataSourceMapper = (data: ConfigRevision) => {
    const mappedData = {
      ...data,
      selected: selectedConfigRevision?.id === data.id,
    };
    return mappedData;
  };

  return (
    <Container full style={{ width: '100%' }}>
      <PageHeader title={t('title')} level={2} />
      <PaginatedTable
        paginatedResource={configRevisionsQueryResponse}
        columns={columns}
        rowKey={(config) => config.id}
        data-testid="configuration-table"
        onParamsChange={onFilterAndSortChange}
        dataSourceMapper={dataSourceMapper}
      />
    </Container>
  );
}

function getConfigRevisionsColumns(
  t: MimicTranslationFunction<'configRevisions'>,
  filters: ConfigRevisionFilters,
  getConfigRevisionPath: (configRevision: SelectableConfigRevision) => string,
  onConfigRevisionSelect?: (configRevision: SelectableConfigRevision) => void,
): ColumnsType<SelectableConfigRevision> {
  const allColumns: ColumnType<SelectableConfigRevision>[] = [
    {
      dataIndex: 'select',
      width: '60px',
      render: (_, configRevision) => {
        if (!onConfigRevisionSelect) return null;
        return (
          <Radio
            dataTestId="select-config-revision"
            onClick={() => onConfigRevisionSelect(configRevision)}
            checked={configRevision.selected || false}
          />
        );
      },
    },
    {
      title: <span data-testid="config-revision-revision-number">#</span>,
      dataIndex: 'revisionNumber',
      width: '50px',
    },
    {
      title: <span data-testid="config-revision-description">{t('table.revision')}</span>,
      dataIndex: 'description',
      filterMode: 'menu',
      render: (description: string, configRevision) => {
        return (
          <GuardedLink
            to={getConfigRevisionPath(configRevision)}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="config-revision-path"
            requiredRole="editor"
          >
            <MimicHighlighter searchText={filters.description} text={description} />
          </GuardedLink>
        );
      },
    },
    {
      title: <span data-testid="config-revision-author">{t('table.author')}</span>,
      dataIndex: 'author',
      render: (_, configRevision) => {
        return (
          <Flex gap="sm" align="center">
            <Avatar size="sm" text={configRevision.createdBy.displayName[0]} />
            <Text>{configRevision.createdBy.displayName}</Text>
          </Flex>
        );
      },
    },
    {
      title: <span data-testid="config-revision-id">{t('table.id')}</span>,
      dataIndex: 'id',
      width: '400px',
      render: (id) => {
        return (
          <Text mono copyable>
            {id}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="config-revision-created-at">{t('table.createdAt')}</span>,
      dataIndex: 'createdAt',
      render: (createdAt) => {
        return <RelativeTime date={createdAt} />;
      },
    },
  ];

  if (!onConfigRevisionSelect) {
    return allColumns.filter((column) => column.dataIndex !== 'select');
  }
  return allColumns;
}
