/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { z } from 'zod';
import {
  PaginationParams,
  buildPaginatedResponseSchema,
  sortable,
} from '../../openapi';
import { OPENAPI_TENANT_ID, TYPE_UUID } from '../../types';
import { NodeHostname, NodeName } from '../nodes/schemas';

export const uuid = z
  .string()
  .uuid()
  .openapi({ example: '550e8400-e29b-41d4-a716-446655440000' });

export const displayName = z.string().openapi({ example: 'Mary Smith' });

export const role = z.string().openapi({ example: 'admin' });
export const reason = z
  .string()
  .openapi({ example: 'Clearing hallmarks for testing' });

export const SubjectData = z
  .object({
    name: NodeName.optional(),
    nodeId: uuid.optional(),
    hostname: NodeHostname.optional(),
    userId: uuid.optional(),
    updatedBy: uuid.optional(),
    displayName: displayName.optional(),
    role: role.optional(),
    reason: reason.optional(),
  })
  .openapi('NotificationSubjectData');

export const NotificationSchema = z
  .object({
    id: TYPE_UUID.openapi({
      'x-go-name': 'ID',
      example: '550e8400-e29b-41d4-a716-446655440000',
    }),
    tenantID: OPENAPI_TENANT_ID,
    type: z.string(),
    title: z.string(),
    subtitle: z.string(),
    subjectData: SubjectData,
    dateCreated: z.date().openapi({ format: 'date-time' }),
    dateOfEvent: z.date().openapi({ format: 'date-time' }),
  })
  .openapi('Notification');

export const NotificationParamsSchema = z
  .object({
    nodeId: uuid.optional(),
  })
  .openapi('NotificationFilters');

export const NotificationSortableFields = sortable(
  'NotificationSortableFields',
  [],
);

// response schemas
export const PaginatedNotificationResponseSchema = buildPaginatedResponseSchema(
  'PaginatedNotificationsResponse',
  NotificationSchema,
  NotificationParamsSchema,
  NotificationSortableFields,
);

export const NotificationsQueryParamsSchema = PaginationParams.merge(
  z.object({
    filters: NotificationParamsSchema.optional(),
    sort: NotificationSortableFields.optional(),
  }),
);
