/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Node, NodeFilters, PaginatedNodesResponse, Tag } from '@/client/';
import { CountLabel } from '@/components/CountLabel';
import { GuardedButton } from '@/components/GuardedButton';
import { PageHeader } from '@/components/PageHeader';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Segmented, SegmentedValue } from '@/primitives/Segmented';
import { TableProps } from '@/primitives/Table';
import { setJobWizardSelectedNodes } from '@/state/jobWizardStore';
import { UpdateParams } from '@/utils/params';
import { NodesColumns } from '@/v1/components/NodesTable/getNodesColumns';
import { NodesTable } from '@/v1/components/NodesTable/NodesTable';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

enum NodeFilter {
  CONFIGURED = 'configured',
  PENDING = 'pending',
}

export type NodeListPageProps = {
  tenantID: string;
  tags: Tag[] | undefined;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
  filters: NodeFilters;
  configuredNodesQuery: UseQueryResult<PaginatedNodesResponse>;
  pendingNodesQuery: UseQueryResult<PaginatedNodesResponse>;
  getNodeConfigurationPath: (nodeConfigId: string) => string;
  onAssignConfiguration: (node: Node) => void;
};

const pendingColumns: NodesColumns[] = [
  'hostname',
  'lastSeen',
  'operatingSystem',
  'dateCreated',
  'assignConfiguration',
];

const configuredColumns: NodesColumns[] = [
  'hostname',
  'tags',
  'appliedOperationalState',
  'connectivityState',
  'operatingSystem',
  'lastSeen',
  'appliedConfiguration',
];

export function NodeListPage({
  tenantID,
  tags,
  onParamsChange,
  filters,
  configuredNodesQuery,
  pendingNodesQuery,
  getNodeConfigurationPath,
  onAssignConfiguration,
}: NodeListPageProps) {
  const { t } = useMimicTranslation('nodes');
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const [sameOs, setSameOs] = useState<boolean>(true);
  const [showDifferentOsError, setShowDifferentOsError] = useState<boolean | undefined>();
  const queryClient = useQueryClient();

  useSubscribeToEvents(
    ['node:updated', 'node-config:assigned', 'node:connectivity-updated', 'notification:created', 'job:updated'],
    () => {
      queryClient.invalidateQueries({ queryKey: ['nodes', tenantID] });
    },
  );

  const onChangeActiveFilter = (value: SegmentedValue) => {
    if (value === NodeFilter.CONFIGURED) {
      onParamsChange({ filters: { isOperating: true }, paginationParams: { number: 1 } });
    } else if (value === NodeFilter.PENDING) {
      onParamsChange({ filters: { isOperating: false }, paginationParams: { number: 1 } });
    }
  };

  const activeFilterValue = filters.isOperating === false ? NodeFilter.PENDING : NodeFilter.CONFIGURED;
  const configuredCount = configuredNodesQuery.data?.meta.page.totalItems;
  const pendingCount = pendingNodesQuery.data?.meta.page.totalItems;

  const nodesQuery = filters.isOperating ? configuredNodesQuery : pendingNodesQuery;
  const visibleColumns = filters.isOperating ? configuredColumns : pendingColumns;

  const rowSelection: TableProps<Node>['rowSelection'] = {
    type: 'checkbox',
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedNodeIds,
    onChange: (_selectedKeys: React.Key[], selectedRows: Node[]) => {
      const selectedOses = selectedRows.map((node) => node.operatingSystem);
      const uniqueOses = new Set(selectedOses);
      const newSameOsVal = uniqueOses.size === 1 || selectedRows.length === 0;
      setSameOs(newSameOsVal);
      const selectedIds = selectedRows.map((node) => node.id);
      setSelectedNodeIds(selectedIds);
      setJobWizardSelectedNodes(selectedRows);
      if (newSameOsVal) {
        setShowDifferentOsError(false);
      }
    },
    getCheckboxProps: (node: Node) => ({
      name: node.name,
    }),
  };

  const selectionWarning = !sameOs ? t('banner.differentOSWarning') : undefined;
  const selectionError = showDifferentOsError ? t('banner.differentOSError') : undefined;

  return (
    <Container>
      <PageHeader title={t('title')} level={2}>
        <Segmented
          dataTestId="pending-switcher"
          defaultValue={NodeFilter.CONFIGURED}
          value={activeFilterValue}
          onChange={onChangeActiveFilter}
          options={[
            {
              label: (
                <CountLabel
                  text={t('configured')}
                  count={configuredCount!}
                  loading={configuredNodesQuery.isPending}
                  data-testid="segment-confirmed"
                />
              ),
              value: NodeFilter.CONFIGURED,
            },
            {
              label: (
                <CountLabel
                  text={t('pending')}
                  count={pendingCount!}
                  loading={pendingNodesQuery.isPending}
                  data-testid="segment-pending"
                />
              ),
              value: NodeFilter.PENDING,
            },
          ]}
        />
      </PageHeader>
      <NodesTable
        nodesQuery={nodesQuery}
        onParamsChange={onParamsChange}
        columns={visibleColumns}
        tags={tags}
        tenantID={tenantID}
        onAssignConfiguration={onAssignConfiguration}
        getNodeConfigurationPath={getNodeConfigurationPath}
        selectedNodeIds={selectedNodeIds}
        selectionWarning={selectionWarning}
        selectionError={selectionError}
        rowSelection={rowSelection}
        primaryAction={
          <GuardedButton
            requiredRole="superadmin"
            type="primary"
            style={{ height: '36px' }}
            onClick={() => {
              if (!sameOs) {
                setShowDifferentOsError(true);
                return;
              }
              navigate(`/tenants/${tenantID}/job-configure-nodes`);
            }}
          >
            {t('configureNodes')}
          </GuardedButton>
        }
      />
    </Container>
  );
}
