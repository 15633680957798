/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { MenuProps } from 'antd';
import { useState } from 'react';

import { Tenant, User } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { useTheme } from '@/hooks/useTheme';
import { Avatar } from '@/primitives/Avatar';
import { Dropdown } from '@/primitives/Dropdown';
import { Skeleton } from '@/primitives/Skeleton';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';
import { useGetTenantListQuery } from '@/v1/utils/hooks/useGetTenantListQuery';
import { useShowErrorMessage } from '@/v1/utils/hooks/useShowErrorMessage';

import './TenantSwitcher.css';
import { TenantOption } from './TenantOption';

export type TenantSwitcherProps = {
  currentUser?: User;
  selectedTenantID?: string;
  onSelectTenant: (tenant: Tenant) => void;
};

export function TenantSwitcher({ currentUser, selectedTenantID, onSelectTenant }: TenantSwitcherProps) {
  const { t } = useMimicTranslation('tenants');
  const { data, isError, error } = useGetTenantListQuery({ currentUser });
  useShowErrorMessage({ showError: isError, errorMessage: t('errorFetchingTenants') });
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const tenants = data?.data;
  const selectedTenant = tenants?.find((tenant) => tenant.id === selectedTenantID);

  if (isError) Sentry.captureException(error);

  if (currentUser?.role !== 'superadmin' || isError) {
    return null;
  }

  if (!tenants || !selectedTenant) {
    return <Skeleton loading />;
  }

  const items: MenuProps['items'] = tenants.map((tenant) => ({
    label: <TenantOption selectedTenant={selectedTenant} tenant={tenant} />,
    key: tenant.id,
    onClick: () => onSelectTenant(tenant),
  }));

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        style: {
          maxWidth: tokens.color.components.menu.sidebarMenuWidth,
          background: theme.tokens.color.components.menu.menuLvl2Bg,
        },
        defaultSelectedKeys: [selectedTenant.id],
      }}
      onOpenChange={(newState) => setOpen(newState)}
      dataTestId="tenant-switcher"
    >
      <div className="tenant-switcher" style={{ backgroundColor: theme.tokens.color.components.menu.menuLvl2Bg }}>
        <div>
          <Avatar
            size="xxs"
            text={selectedTenant.name.charAt(0).toUpperCase()}
            color={tokens.color.gold.gold03}
            backgroundColor={tokens.color.text.textSubtle}
            shape="square"
            dataTestId="tenant-switcher-avatar"
          />
        </div>
        <Text style={{ color: theme.antdComponents?.Menu?.itemColor }} ellipsis>
          {selectedTenant?.name.split('').map(() => '•')}
        </Text>
        <div>
          {!open && (
            <DownOutlined style={{ fontSize: tokens.size.font.sm, color: theme.antdComponents?.Menu?.itemColor }} />
          )}
          {open && (
            <UpOutlined style={{ fontSize: tokens.size.font.sm, color: theme.antdComponents?.Menu?.itemColor }} />
          )}
        </div>
      </div>
    </Dropdown>
  );
}
