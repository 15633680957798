import { UseQueryResult } from '@tanstack/react-query';

import { ApiError } from '@/client';
import { SectionHTTPError } from '@/components/SectionHTTPError';
import { Skeleton } from '@/primitives/Skeleton';

interface QueryWrapperProps<T> {
  queryResult: Pick<UseQueryResult<T>, 'data' | 'isLoading' | 'isFetching' | 'isError' | 'error'>;
  children: (data: T) => React.ReactNode;
}

/**
 * A wrapper component that handles common states and errors for React Query hooks.
 * Provides consistent loading, error, and data states across the application.
 *
 * @template T The type of data returned by the query
 *
 * Features:
 * - Loading state with Skeleton component
 * - HTTP error handling (403, 404, 500)
 * - Type-safe data rendering
 * - ApiError vs unknown error differentiation
 *
 * @example
 * ```tsx
 * function NodeDetails() {
 *   const { data, isLoading, error } = useNodeByIdQuery({ nodeId });
 *   return (
 *     <QueryWrapper queryResult={{ data, isLoading, error }}>
 *       {(response) => (
 *         <div>
 *           <h2>{response.name}</h2>
 *         </div>
 *       )}
 *     </QueryWrapper>
 *   );
 * }
 * ```
 *
 * @param props.queryResult - Subset of React Query result containing data, loading, and error states
 * @param props.children - Render function that receives the query data
 * @returns React element based on query state (loading/error/data)
 */
export function QueryWrapper<T>({ queryResult: query, children }: QueryWrapperProps<T>) {
  const { data, isLoading, error } = query;
  const apiError = error instanceof ApiError ? error : undefined;
  const unknownError = error && !(error instanceof ApiError);

  if (isLoading) return <Skeleton loading />;
  if (unknownError) return <SectionHTTPError httpStatus="500" />;
  if (apiError) {
    switch (apiError.status) {
      case 403:
        return <SectionHTTPError httpStatus="403" />;
      case 404:
        return <SectionHTTPError httpStatus="404" />;
      default:
        return <SectionHTTPError httpStatus="500" />;
    }
  }

  if (!data) return <SectionHTTPError httpStatus="404" />;

  return children(data);
}
