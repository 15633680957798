/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import {
  CheckCircleFilled,
  ClockCircleFilled,
  ExclamationCircleFilled,
  LoadingOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { Job, JobFilters, PaginatedJobsResponse } from '@/client';
import MimicHighlighter from '@/components/MimicHighlighter';
import { PaginatedTable } from '@/components/PaginatedTable';
import { RelativeTime } from '@/components/RelativeTime';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { JobListRowExpanded } from '@/pages/Jobs/JobListRowExpanded';
import { Flex } from '@/primitives/Flex';
import { OsIcon } from '@/primitives/Icons';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { Tooltip } from '@/primitives/Tooltip';
import { tokens } from '@/theme';
import { UpdateParams } from '@/utils/params';

export type JobListTableProps = {
  tenantID: string;
  jobsData: PaginatedJobsResponse;
  jobFilters: JobFilters;
  updateQueryParams: UpdateParams<Job, Partial<JobFilters>>;
};

export function JobListTable({ tenantID, jobsData, updateQueryParams, jobFilters }: JobListTableProps) {
  const { t } = useMimicTranslation('jobs');
  const columns = getJobColumns(t, jobFilters, tenantID);
  return (
    <PaginatedTable
      data={jobsData}
      columns={columns}
      rowKey={(config) => config.id}
      data-testid="configuration-table"
      onParamsChange={updateQueryParams}
      expandable={{
        expandedRowRender,
        rowExpandable: () => true,
      }}
    />
  );
}
function getJobColumns(
  t: MimicTranslationFunction<'jobs'>,
  jobFilters: JobFilters,
  tenantID: string,
): ColumnsType<Job> {
  return [
    {
      title: <span data-testid="jobs.name">{t('table.jobName')}</span>,
      dataIndex: 'name',
      sorter: true,
      filterMode: 'menu',
      render: (name, job) => {
        return (
          <Link
            to={`/tenants/${tenantID}/jobs/${job.id}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="job-details-link"
          >
            <Flex gap="xxs">
              <MimicHighlighter searchText={jobFilters.name} text={name} />
              {job.status === 'running' && <LoadingOutlined />}
            </Flex>
          </Link>
        );
      },
    },
    {
      title: <span data-testid="jobs.targetConfig">{t('table.targetConfig')}</span>,
      dataIndex: 'targetConfig',
      sorter: true,
      filterMode: 'menu',
      render: (_, job) => {
        const { targetConfig } = job;
        const config = `${targetConfig.nodeConfig.name} · #${targetConfig.configRevision.revisionNumber}`;
        return (
          <Link
            to={`/tenants/${tenantID}/node-configs/${targetConfig.nodeConfig.id}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <MimicHighlighter searchText={jobFilters.targetConfig} text={config} />
          </Link>
        );
      },
    },
    {
      title: <span data-testid="jobs.operatingSystem">{t('table.os')}</span>,
      dataIndex: 'operatingSystem',
      filters: [
        { text: 'Windows', value: 'windows' },
        { text: 'Linux', value: 'linux' },
      ],
      render: (_, job) => (
        <Tooltip title={job.targetConfig.nodeConfig.operatingSystem} placement="right">
          <div data-testid="operating-system-icon">
            <OsIcon name={job.targetConfig.nodeConfig.operatingSystem} />
          </div>
        </Tooltip>
      ),
      width: '70px',
    },
    {
      title: <span data-testid="jobs.timedout">{t('table.timedout')}</span>,
      dataIndex: 'nodeStatus',
      width: '100px',
      render: (nodeStatus: Job['nodeStatus']) => {
        if (!nodeStatus.timeout) return null;
        return (
          <Flex gap="xs">
            <ClockCircleFilled style={{ color: tokens.color.error.error }} />
            <Text>{nodeStatus.timeout}</Text>
          </Flex>
        );
      },
    },
    {
      title: <span data-testid="jobs.failed">{t('table.failed')}</span>,
      dataIndex: 'nodeStatus',
      width: '100px',
      render: (nodeStatus: Job['nodeStatus']) => {
        if (!nodeStatus.error) return null;
        return (
          <Flex gap="xs">
            <ExclamationCircleFilled style={{ color: tokens.color.error.error }} />
            <Text>{nodeStatus.error}</Text>
          </Flex>
        );
      },
    },
    {
      title: <span data-testid="jobs.warning">{t('table.warning')}</span>,
      dataIndex: 'nodeStatus',
      width: '100px',
      render: (nodeStatus: Job['nodeStatus']) => {
        if (!nodeStatus.warning) return null;
        return (
          <Flex gap="xs">
            <WarningFilled style={{ color: tokens.color.yellow.yellow06 }} />
            <Text>{nodeStatus.warning}</Text>
          </Flex>
        );
      },
    },
    {
      title: <span data-testid="jobs.success">{t('table.success')}</span>,
      dataIndex: 'nodeStatus',
      width: '100px',
      render: (nodeStatus: Job['nodeStatus']) => {
        if (!nodeStatus.success) return null;
        return (
          <Flex gap="xs">
            <CheckCircleFilled style={{ color: tokens.color.green.green06 }} />
            <Text>{nodeStatus.success}</Text>
          </Flex>
        );
      },
    },
    {
      title: <span data-testid="jobs.dateCreated">{t('table.started')}</span>,
      dataIndex: 'dateCreated',
      sorter: true,
      key: 'dateCreated',
      render: (dateCreated) => {
        return <RelativeTime date={dateCreated} />;
      },
      width: '200px',
    },
    {
      title: <span data-testid="jobs.creatorName">{t('table.startedBy')}</span>,
      dataIndex: 'creatorName',
      sorter: true,
      key: 'creatorName',
      render: (_, job: Job) => {
        return <MimicHighlighter searchText={jobFilters.targetConfig} text={job.createdBy.displayName} />;
      },
      width: '200px',
    },
  ];
}

const expandedRowRender = (record: Job, _i: number, _j: number, expanded: boolean) => {
  if (!expanded) return null;
  return <JobListRowExpanded job={record} />;
};
